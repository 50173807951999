<template>
  <div class="refund-detail">
    <div class="refund-order-box font17 bold t-primary" v-if="detail?.orderItems?.length">
      {{detail.orderItems[0].productName}}
    </div>
    <div class="refund-c">
      <div class="refund-info-box">
        <p class="font15 t-primary bold mb12">退款信息</p>
        <formBox :formData="formData" :formList="formList" />
      </div>
      <div class="refund-info-box">
        <p class="font15 t-primary bold mb12">退款说明</p>
        <van-field
          v-model="message"
          rows="3"
          autosize
          label=""
          type="textarea"
          placeholder="请输入留言"
        />
      </div>
    </div>
    <div class="buy-btn">
      <div class="detail-price">
        <van-button type="primary" round style="width: 100%" @click="refundOrder">提交申请</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { GetRefundReasons, RefundOrder, GetOrderItem } from '@/api'
import formBox from '@/components/formBox'
export default {
  name: 'refund-detail',
  components: {
    formBox
  },
  data () {
    return {
      formData: {
        b: ''
      },
      reasons: [],
      message: '',
      id: '',
      detail: {}
    }
  },
  computed: {
    formList () {
      const arr = [{
        type: 'Select',
        label: '退款原因',
        prop: 'refundReasonCode',
        selectType: 'refund',
        headerName: '退款原因',
        options: this.reasons,
        placeholder: '请选择',
        defaultProps: {
          label: 'value',
          value: 'code'
        }
      },{
        type: 'Txt',
        label: '退款金额',
        prop: 'b',
        class: 'red'
      }]
      return arr
    }
  },
  created () {
    this.id = this.$route.params.id  // orderNo
    this.$store.commit('setTitle', '退款申请')
    // this.$setTitle('退款申请')
    this.getRefundReasons()
    this.getDetail()
  },
  methods: {
    async getRefundReasons () {
      let data = await GetRefundReasons()
      this.reasons = data
    },
    async refundOrder () {
      const params = {
        orderDetailId: this.detail.orderItems[0].orderDetlId,
        ...this.formData,
        remark: this.message
      }
      await RefundOrder(params)
      this.$router.replace('/order-detail/'+this.id)
    },
    async getDetail () {
      let data = await GetOrderItem({orderNo: this.id})
      this.detail = data
      this.formData.b = '￥'+this.detail.paymentAmount
      console.log(this.detail)
    }
  }
}
</script>

<style lang="scss">
.refund-detail {
  flex: 1;
  .refund-order-box{
    padding: 15px;
    box-shadow: 0px 0px 35px 0px rgba(215, 215, 215, 0.5);
  }
  .refund-c{
    padding: 15px;
  }
  .refund-info-box{
    border-radius: 10px;
    padding: 18px 15px;
    background: #fff;
    box-shadow: 0px 0px 35px 0px rgba(215, 215, 215, 0.5);
    margin-bottom: 15px;
    .form-element.van-cell{
      padding: 0;
    }
    .van-cell{
      padding: 10px 0;
      font-size: 14px;
      ::after{
        content: none;
      }
    }
  }
  .red {
    .van-cell__value{
      color: #FF3C35;
      span{
        font-weight: bold;
      }
    }
  }
}
</style>